<template>
  <v-sheet class="pa-5" elevation="1">
    <h1 class="display-1">Orders</h1>
    <v-divider class="mt-3"></v-divider>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-select
          v-model="orderStatus"
          :items="orderStatusItems"
          label="Status"
          outlined
          hide-details
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="6">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn color="primary" light block to="/manager/new-order">
          <v-icon left> mdi-plus-circle </v-icon>
          Ajouter Order
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-select
          v-model="van"
          :items="vanItems"
          item-text="brandModel"
          item-value="id"
          return-object
          outlined
          dense
          hide-details
          label="Van"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-select
          :items="driverItems"
          v-model="driver"
          item-text="fullNameWithId"
          return-object
          outlined
          dense
          label="Driver"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3" align-self="start">
        <div class="d-flex justify-center justify-lg-end">
          <v-btn text small outlined color="grey darken-2" @click="currentDay">
            Day
          </v-btn>
          <v-btn text small outlined color="grey darken-2" @click="currentWeek">
            Week
          </v-btn>
          <v-btn
            text
            small
            outlined
            color="grey darken-2"
            @click="currentMonth"
          >
            Month
          </v-btn>
          <v-btn text small outlined color="grey darken-2" @click="currentYear">
            Year
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="3">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              outlined
              append-icon="mdi-calendar"
              dense
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
            color="primary"
            locale="fr"
            @change="dateFilterUpdate(dates)"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <div class="mt-5">
      <v-data-table
        :headers="headers"
        :items="orders"
        :loading="tableLoader"
        :search="search"
        hide-default-footer
        class="elevation-0"
      >
        <template v-slot:[`item.trip`]="{ item }">
          <div
            class="d-flex flex-column align-center justify-center py-3 my-3"
            style="background-color: #d2ebd3"
          >
            <p class="my-0">{{ item.trip.date | fromUnix }}</p>
            <p class="my-0">{{ item.trip.time | fromUnixTime }}</p>
            <p class="my-0">{{ item.dropOffLocation.title }}</p>
          </div>
        </template>
        <template v-slot:[`item.tripInfo`]="{ item }">
          <div
            class="d-flex flex-column align-center justify-center py-3 my-3"
            style="background-color: #d2ebd3"
          >
            <p class="my-0">{{ item.tripInfo.customerName }}</p>
            <p class="my-0">{{ item.tripInfo.passengers }} Passenger(s)</p>
            <p class="my-0">{{ item.tripInfo.luggages }} Luggage(s)</p>
          </div>
        </template>
        <template v-slot:[`item.orderStatus`]="{ item }">
          <div class="d-flex flex-column align-center justify-center">
            {{ item.orderStatus }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row align-center justify-center">
            <v-btn text icon color="grey" @click="goToOrder(item.id)">
              <v-icon class="mr-3">mdi-eye</v-icon>
            </v-btn>
            <!-- <v-btn text icon color="accent">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn text icon color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn> -->
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="newOrderDialog"
      v-if="newOrderDialog"
      persistent
      scrollable
      width="720"
    >
      <order-form :toggle="toggleNewOrder" />
    </v-dialog>
  </v-sheet>
</template>

<script>
import moment from "moment";
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default {
  components: {
    OrderForm: () => import("@/components/order/OrderForm2.vue"),
  },

  data: () => ({
    dates: ["2021-09-10", "2021-09-20"],
    dateMenu: null,
    newOrderDialog: false,
    tableLoader: true,
    orderStatusItems: ["All", "Pending", "Cancelled"],
    orderStatus: "All",
    vanItems: [],
    van: "",
    driverItems: [],
    dateRange: {
      start: 0,
      end: 0,
    },
    driver: "",
    search: "",
    orders: [],
    headers: [
      {
        text: "Order Id",
        align: "center",
        value: "customId",
      },
      { text: "Van ID", align: "center", value: "vanCustomId" },
      { text: "Trip", align: "center", value: "trip" },
      { text: "Orderer", align: "center", value: "tripInfo" },
      { text: "Driver", align: "center", value: "driverFullName" },
      { text: "Status", align: "center", value: "orderStatus" },
      { text: "Actions", align: "center", value: "actions" },
    ],
  }),

  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  watch: {
    async van(val) {
      this.orders = await this.$store.dispatch("order/getOrdersTableByVanId", {
        vanId: val.id,
        status: this.orderStatus,
      });
      console.log(this.orders);
    },
    async driver(val) {
      this.orders = await this.$store.dispatch(
        "order/getOrdersTableByDriverId",
        { driverId: val.id, status: this.orderStatus }
      );
      console.log(this.orders);
    },
    async orderStatus(val) {
      console.log(val);
      if (val == "All")
        this.orders = await this.$store.dispatch("order/getOrdersAll");
      if (val == "Cancelled")
        this.orders = await this.$store.dispatch(
          "order/getOrdersTableByStatus",
          { status: "Cancelled" }
        );
      if (val == "Pending")
        this.orders = await this.$store.dispatch(
          "order/getOrdersTableByStatus",
          { status: "Pending" }
        );
    },
  },

  async created() {
    this.currentWeek();
    // this.orders = await this.$store.dispatch('order/getOrdersByDateRange', this.dateRange)
    // this.orders = await this.$store.dispatch("order/getOrders");
    this.$store.dispatch("van/getVans").then((vanArray) => {
      this.vanItems = vanArray;
    });

    this.orders = await this.$store.dispatch("order/getOrdersAll");

    this.driverItems = await this.$store.dispatch("driver/getDrivers");

    this.tableLoader = false;
  },

  methods: {
    async dateFilterUpdate(val) {
      let start = dayjs(val[0]).format("YYYY-MM-DD");
      let end = dayjs(val[1]).format("YYYY-MM-DD");

      this.dateRange.start = Number(dayjs(start).format("X"));
      this.dateRange.end = Number(dayjs(end).format("X"));

      console.log(this.dateRange);

      this.orders = await this.$store.dispatch(
        "order/getOrdersByDateRange",
        this.dateRange
      );
    },

    toggleNewOrder() {
      this.newOrderDialog = !this.newOrderDialog;
    },

    goToOrder(id) {
      this.$router.push({
        name: "Manager-Orders-Single",
        params: { id: id },
      });
    },

    async currentDay() {
      let start = moment().format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");

      this.dateRange.start = Number(dayjs(start).format("X"));
      this.dateRange.end = Number(dayjs(end).format("X"));

      this.dates = [start, end];

      this.orders = await this.$store.dispatch(
        "order/getOrdersByDateRange",
        this.dateRange
      );
    },

    async currentWeek() {
      let start = moment().subtract(7, "days").format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");

      this.dateRange.start = Number(dayjs(start).format("X"));
      this.dateRange.end = Number(dayjs(end).format("X"));

      this.dates = [start, end];

      this.orders = await this.$store.dispatch(
        "order/getOrdersByDateRange",
        this.dateRange
      );
    },

    async currentMonth() {
      let start = moment().subtract(1, "month").format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");

      this.dateRange.start = Number(dayjs(start).format("X"));
      this.dateRange.end = Number(dayjs(end).format("X"));

      this.dates = [start, end];

      this.orders = await this.$store.dispatch(
        "order/getOrdersByDateRange",
        this.dateRange
      );
    },

    async currentYear() {
      let start = moment().subtract(1, "year").format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");

      this.dates = [start, end];

      this.orders = await this.$store.dispatch(
        "order/getOrdersByDateRange",
        this.dateRange
      );
    },

    checkObject(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>

<style></style>
